/*
|--------------------------------------------------------------------------
| Typography
|--------------------------------------------------------------------------
*/

$font-family-sans-serif:'AvenirNextLTW01-Regular', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;



/*
|--------------------------------------------------------------------------
| Color Definitions
|--------------------------------------------------------------------------
*/

// Body
$site-background: #a1a1a1;


// Main

$site-white-color: #fff;
$site-yellow-color: #f5dd6f;
$site-blue-color: #01044c;

$site-text-color: #1d1d1d;
$site-link-color: #8c1c27;

$custom-file-text: (
  en: "Browse",
  sv: "Välj"
);



/*
|--------------------------------------------------------------------------
| Bootstrap
|--------------------------------------------------------------------------
*/

@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap-vue/src/index.scss';



/*
|--------------------------------------------------------------------------
| Global
|--------------------------------------------------------------------------
*/

html, body {
  background-color: $site-background;

  font-family:'AvenirNextLTW01-Regular';
  color: $site-text-color;
}

p {
  font-family:'AvenirNextLTW01-Regular';
  color: $site-text-color;
}

a {
  color: $site-link-color;

  &:hover {
    color: $site-link-color;
  }
}



/*
|--------------------------------------------------------------------------
| Header
|--------------------------------------------------------------------------
*/

header {
  position: relative;
  height:  130px;

  margin-bottom: 00px;
  background-color: $site-white-color;

  .logo {
    margin-left: 20px;
    margin-right: 25px;
    margin-top: 10px;
  }

  h1 {
    font-family:'AvenirNextLTW01-UltraLi';

    color: #919191;

    padding-top: 30px;
  }

  h3 {
    font-family:'AvenirNextLTW01-UltraLi';

    color: #919191;

    padding-top: 30px;
  }
}

.header-bottom {
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;

  height:  10px;

  background-color: $site-yellow-color;
}

.header-wave {
  position: absolute;

  bottom: 10px;
  right: 0px;
}



/*
|--------------------------------------------------------------------------
| Main
|--------------------------------------------------------------------------
*/

.main-area {
  padding: 0px;

  background-color: $site-white-color;
}

.main-text {
  position: absolute;

  bottom: 20px;

  width: 96.66666666666666%;

  color: #fff;
}

.main-content-area {
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;

  background-color: $site-white-color;
}

.home-sections {
  margin-top: 20px;
}

.home-blue-titles {
  margin: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 0px;
  padding-right: 0px;

  background-color: $site-blue-color;

  font-family:'AvenirNextLTW01-Regular';
  color: #fff;
  font-size: 1.3em;
}

.home-sections-main {
  padding-top: 20px;
}

.home-hermes-image {
  max-width: 100%;

  height: auto;
}

.home-hermes-title {
  padding-top: 20px;
  padding-bottom: 10px;
}

.home-hermes {
  h4 {
    font-size: 0.9em;
  }

  p {
    padding-top: 10px;
  }
}

.temp-page {
  margin: 0px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.page-content {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.image-caption {
  font-style: italic;

  font-size: 0.9em;
}

.statutes-intro {
  margin: 0px;
  padding: 0px;

  font-style: italic;
}

.statutes-intro_2 {
  margin: 10px 0px 0px 0px;
}

.statutes-paragraph-header {
  margin: 20px 0px 5px 0px;
}

.statutes-paragraph {
  margin: 10px 0px 0px 0px;
}

.contact-info {
  margin: 0px;
  padding: 0px;
}

.contact-info-smaller {
  margin: 0px 0px 5px 0px;
  padding: 0px;

  font-size: 0.9em;
}

.contact-header {
  font-size: 1.5em;

  margin-top: 25px;
}


/*
|--------------------------------------------------------------------------
| Footer
|--------------------------------------------------------------------------
*/

footer {
  margin-bottom: 50px;
  padding: 0px;

  background-color: #292929;

  .info-background {
    margin: 0px;

    padding-left: 10px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;

    background-color: #292929;
  }

  h3 {
    color: #fff;
  }

  p {
    color: #b3b3b3;
  }

  .address {
    margin: 0px;
    margin-bottom: 2px;

    font-size: 0.9em;
    color: #b3b3b3;
  }

  .link {
    margin: 0px;
    margin-bottom: 5px;

    font-size: 0.9em;
    color: #b3b3b3;
  }

  .copyright {
    margin: 0px;
    margin-bottom: 5px;

    color: #fff;
  }

  .copyright-background {
    margin: 0px;

    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 6px;

    background-color: #000;

    font-size: 0.7em;
  }
}



/*
|--------------------------------------------------------------------------
| Table Buttons
|--------------------------------------------------------------------------
*/

.table-button-extra-space {
  margin-right: 5px;
}




.social-media-icon {
  width: 50px;
  height: 50px;

  background-color: #fff;
}
